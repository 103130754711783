<template>
  <vee-form
    ref="form"
    v-slot="{ invalid }"
  >
    <v-form v-model="isFormValid">
      <v-container v-if="$vuetify.breakpoint.lgAndUp">
        <v-row justify="space-around">
          <v-col
            offset-xl="2"
            xl="2"
            cols="3"
          >
            <configuration-set-side-navigation
              :components="components"
              :speditor="speditor"
              :current-component="currentComponent"
              :is-side-bar-active="isSideBarActive"
              :loading="loading"
              @@changeComponent="changeComponent"
            />
          </v-col>
          <v-col
            offset-xl="1"
            xl="5"
            cols="7"
            class="ml-0"
          >
            <slot name="content" />
          </v-col>
          <v-col cols="2">
            <configuration-set-actions
              :invalid="(useVForm ? !isFormValid : invalid) || loading"
              style="position: fixed; margin-top: 12px"
              @@save="$emit('@save')"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="$vuetify.breakpoint.mdAndDown">
        <v-row justify="space-around">
          <v-col
            cols="4"
            class="mt-3"
          >
            <configuration-set-side-navigation
              :components="components"
              :speditor="speditor"
              :current-component="currentComponent"
              :is-side-bar-active="isSideBarActive"
              :loading="loading"
              @@changeComponent="changeComponent"
            />
          </v-col>
          <v-col
            cols="8"
            class="ml-0 mt-3 mb-9"
          >
            <slot name="content" />
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="$vuetify.breakpoint.mdAndDown">
        <configuration-set-actions
          :invalid="(useVForm ? !isFormValid : invalid) || loading"
          style="position: fixed; bottom: 0; left: 0; width: 100%; z-index: 1000"
          @@save="$emit('@save')"
        />
      </v-container>
    </v-form>
  </vee-form>
</template>

<script lang="ts">
import ConfigurationSetSideNavigation from '@/components/shared/ConfigurationSetSideNavigation.vue';
import ConfigurationSetActions from '@/components/shared/ConfigurationSetActions.vue';

export default {
  components: {
    ConfigurationSetActions,
    ConfigurationSetSideNavigation,
  },

  props: {
    components: { type: Array, default: () => [] },
    speditor: { type: String, default: undefined },
    currentComponent: { type: String, default: 'ServiceAuthorization' },
    isSideBarActive: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    useVForm: Boolean,
  },

  data: () => ({
    isFormValid: false,
  }),

  methods: {
    changeComponent(component) {
      this.$emit('@changeComponent', component);
    },
  },
};
</script>
