<template>
  <v-card
    elevation="0"
    class="action-row pa-2"
  >
    <v-btn
      elevation="0"
      :disabled="invalid"
      @click="$emit('@save')"
    >
      {{ $t('courierSet.save') }}
    </v-btn>
  </v-card>
</template>

<script>
export default {
  props: {
    invalid: { type: Boolean, default: true },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/utilities/variables';

.action-row {
  padding: 5px;
  .v-btn {
    background-color: $primary-lighten1 !important;
    margin-right: 0px;
    width: 100%;
    color: white;
  }
}
</style>
